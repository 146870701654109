import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <section id="privacy-policy" className="privacy-policy">
      <div className="container">
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> June 15, 2024</p>
        <p><strong>Last Updated:</strong> April 03, 2025</p>

        <h2>1. Introduction</h2>
        <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our mobile application.</p>

        <h2>2. Information We Access</h2>
        <p>BrainLog operates entirely on your device and utilizes the Google Drive API for cloud storage. The app accesses, but does not collect or store, the following information:</p>
        <p><strong>Google Account Information:</strong> When you sign into BrainLog with your Google account, the app accesses your basic profile information, such as your email address and profile picture.</p>
        <p><strong>Google Drive Files:</strong> BrainLog can only access Google Drive files created by the app, including:</p>
        <ul>
          <li>Text files you create within the app.</li>
          <li>Files containing your purchase information for premium features.</li>
        </ul>
        <p><strong>Device Files:</strong> With your consent, the app may access files in shared folders on your device.</p>
        <p><strong>Purchase Verification Data:</strong> The app stores purchase-related data locally on your device, such as whether a premium feature has been purchased and verification tokens, to manage access to premium features. This data is encrypted and not shared with third parties.</p>

        <h2>3. Information We Collect</h2>
        <p>BrainLog collects limited usage data for analytics purposes using Firebase Analytics:</p>
        <p><strong>Usage Data:</strong> We collect anonymous data about how you interact with the app, such as feature usage and in-app events. This includes interactions with purchase-related features, such as initiating or completing a purchase.</p>
        <p><strong>Device and Performance Data:</strong> Firebase Analytics may automatically collect information about your device, such as the device type, operating system, and general app performance data.</p>

        <h2>4. How We Use Your Information</h2>
        <p>The information we access and collect is used for the following purposes:</p>
        <p>To provide and improve the app's functionality, including:</p>
        <ul>
          <li>Managing your text files stored in Google Drive or on your device.</li>
        </ul>
        <p>To analyze app performance and user engagement using Firebase Analytics.</p>
        <p>To track in-app interactions, such as the number of times users click on the purchase button, to enhance our premium features.</p>

        <h2>5. Data Sharing and Disclosure</h2>
        <p><strong>No Personal Data Sharing:</strong> BrainLog does not share, sell, or disclose your personal information to third parties.</p>
        <p><strong>Third-Party Analytics:</strong> Usage data collected via Firebase Analytics is processed by Google in accordance with its <a href="https://policies.google.com/privacy" className="link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
        <p><strong>In-App Purchases:</strong> When you make an in-app purchase, the transaction is processed by Google Play, and your payment information is handled in accordance with Google’s <a href="https://policies.google.com/privacy" className="link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. We do not have access to your payment details.</p>

        <h2>6. Data Security</h2>
        <p>We implement industry-standard security measures, including encryption and secure access controls, to protect your data. However, no method of transmission over the internet or electronic storage is entirely secure.</p>

        <h2>7. Data Retention</h2>
        <p>We do not retain your personal information.</p>
        <p>Analytics data is stored by Firebase Analytics as per Google’s data retention policies.</p>
        <p>If you delete the app or disconnect your Google account, your data will remain in your Google Drive in accordance with Google’s data policies.</p>

        <h2>8. Your Rights and Choices</h2>
        <p><strong>Access and Update:</strong> You can manage your personal information through your Google account settings.</p>
        <p><strong>Close Access:</strong> You can revoke BrainLog's access to your profile data and Google Drive files via your Google Account settings under "Data & privacy".</p>
        <p><strong>Manage Locally Stored Data:</strong> You can manage data stored locally on your device, such as purchase verification and usage data, by clearing the app’s data or uninstalling the app through your device settings.</p>

        <h2>9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy periodically. When we do, we will post the updated version on this page.</p>

        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <p><strong>Email Address:</strong> support@brainlog.app</p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;